import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import HomeNavbar from "../components/HomeNavbar";
import Dashboard from "../components/Dashboard";
import SEO from "../components/SEO";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import UpdateLinks from "../components/UpdateLinks";
import { remark } from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import Download from "../components/icons/Download";
import Alert from "../components/Alert";

export const IndexPageTemplate = ({
  title,
  alert,
  image,
  imageAlt,
  multilineHeading,
  subheading,
  mainpitch,
  dashboard,
  waterStorage,
  lakeTahoe,
  riverflow,
}) => (
  <div>
    <SEO title={title} />
    <UpdateLinks />
    <Alert props={alert}/>
    <HomeNavbar />
    <div className="is-relative">
      <div
        style={{
          position: `absolute`,
          zIndex: `-1`,
          width: `100%`,
          height: `100%`,
          top: `0`,
          left: `0`,
          background: `linear-gradient(140deg, #0011a4 0%, #00b4e1 65%, #daffa7 120%)`,
        }}
      ></div>
      <div className="container pt-6 ml-0 pb-6 px-5b px-4-mobile">
        <div className="columns is-vcentered is-gapless">
          <div className="column is-one-half is-two-thirds-tablet-only">
            <h1
              className="has-text-weight-bold is-size-2-touch is-size-1-desktop"
              style={{
                color: "white",
                lineHeight: "1.2",
                padding: "1rem 0 1rem 1rem",
                marginRight: "-1rem",
              }}
            >
              {multilineHeading.line1}
              <br />
              {multilineHeading.line2}
            </h1>
            <h2
              className="has-text-weight-light is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
              style={{
                color: "white",
                lineHeight: "1.6",
                padding: "1rem",
                marginBottom: "3%",
              }}
            >
              {subheading}
            </h2>
          </div>
          <div
            className="column"
            style={{
              width: "100%",
              display: "inline-block",
            }}
          >
            <PreviewCompatibleImage
              imageInfo={{
                image: image,
                alt: imageAlt,
              }}
            />
          </div>
        </div>
        <div className="content overlap-previous-section">
          <section className="mainpitch">

            {mainpitch?.map((section, index) => (

              <div key={section.title || index} className="box-light mb-6">
                <div className={`columns is-multiline is-centered ${index % 2 === 0 ? "is-flex-direction-row-reverse" : "is-flex-direction-row is-vcentered"}`}>
                  <div className="column px-4-fullhd is-4-fullhd is-6-widescreen is-8-desktop is-10-tablet">
                    {section.download?.enabled &&
                      section.download?.file &&
                      section.download?.file?.publicURL ? (
                      <a
                        href={section.download.file.publicURL}
                        target="_blank"
                        rel="noreferrer"
                        className="is-download"
                      >
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: section.image,
                            alt: section.imageAlt,
                            className: `${section.imageBorder
                              ? "grow has-border-white"
                              : "grow"
                              }`,
                          }}
                        />
                      </a>
                    ) : (
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: section.image,
                          alt: section.imageAlt,
                          className: `${section.imageBorder ? " has-border-white" : null
                            }`,
                        }}
                      />
                    )}
                  </div>
                  <div className="column is-full-tablet is-6-widescreen is-8-fullhd pl-5">
                    {section.title ? <div className="tile">
                      <h2 className="has-text-white title is-size-2 is-size-3-mobile mb-5">
                        {section.title}
                      </h2>
                    </div> : null}
                    <div className="section">
                      {section.description ? <div
                        dangerouslySetInnerHTML={{
                          __html: remark()
                            .use(recommended)
                            .use(remarkHtml)
                            .processSync(section.description)
                            .toString(),
                        }}
                      /> : null}
                      {section.link?.url && section.link?.text && (
                        <p className="has-text-weight-light mt-5">
                          {section.link.url.startsWith("/") ?
                            <Link
                              className="has-text-white has-text-weight-bold is-size-7 button has-bg-gradient"
                              href={section.link.url}
                            >
                              {section.link.text}
                            </Link> :
                            <a
                              target="_blank"
                              className="has-text-white has-text-weight-bold is-size-7 button has-bg-gradient"
                              href={section.link.url}
                              rel="noreferrer"
                            >
                              {section.link.text}
                            </a>}
                        </p>
                      )}
                      {section.download?.enabled &&
                        section.download?.file?.publicURL &&
                        section.download?.text ? (
                        <p className="has-text-weight-light mt-5">
                          <a
                            href={section.download.file.publicURL}
                            target="_blank"
                            rel="noreferrer"
                            className="has-text-white has-text-weight-bold is-size-7 button has-bg-gradient has-icon is-download"
                          >
                            {section.download.text} <span className="ml-3"><Download /></span>
                          </a>
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>))}

            <Dashboard
              dashboard={dashboard}
              waterStorage={waterStorage}
              riverflow={riverflow}
              lakeTahoe={lakeTahoe}
            />
          </section>
        </div>
      </div>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  imageAlt: PropTypes.string,
  imageBorder: PropTypes.bool,
  title: PropTypes.string,
  alert: PropTypes.object,
  multilineHeading: PropTypes.object,
  subheading: PropTypes.string,
  mainpitch: [PropTypes.object],
  dashboard: PropTypes.object,
  waterStorage: PropTypes.object,
  riverflow: PropTypes.object,
  lakeTahoe: PropTypes.object,
  partners: PropTypes.object,
};

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const waterStorage = data.waterStorage;
  const riverflow = data.riverflow;
  const lakeTahoe = data.lakeTahoe;

  return (
    <Layout location={location}>
      <IndexPageTemplate
        image={frontmatter.image}
        imageAlt={frontmatter.imageAlt}
        imageBorder={frontmatter.imageBorder}
        title={frontmatter.title}
        alert={frontmatter.alert}
        multilineHeading={frontmatter.multilineHeading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        dashboard={frontmatter.dashboard}
        waterStorage={waterStorage}
        riverflow={riverflow}
        lakeTahoe={lakeTahoe}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    waterStorage: PropTypes.shape({
      totalStorage: PropTypes.number,
      reservoirs: PropTypes.array,
    }),
    riverflow: PropTypes.shape({
      reno: PropTypes.number,
      farad: PropTypes.number,
    }),
    lakeTahoe: PropTypes.shape({
      level: PropTypes.number,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    waterStorage {
      totalStorage
      reservoirs {
        Name
        Storage
      }
    }
    riverflow {
      reno
      farad
    }
    lakeTahoe {
      level
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        alert {
          boldText
          text
          link
          enabled
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        imageAlt
        multilineHeading {
          line1
          line2
        }
        subheading
        mainpitch {
          title
          description
          download {
            enabled
            file {
              publicURL
            }
            text
          }
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageAlt
          imageBorder
          link {
            text
            url
          }
        }
        dashboard {
          heading
          waterYear {
            heading
            percentage1 {
              label
              value
            }
            percentage2 {
              label
              value
            }
            post
            pre
            date
            back {
              pre
              post
            }
            override
          }
          riverflows {
            farad {
              label
              max
              min
              units
              value
            }
            heading
            reno {
              label
              max
              min
              units
              value
            }
            back {
              content
            }
            override
          }
          upstreamWaterStorage {
            heading
            value
            units
            pre
            post
            date
            override
            back {
              post
              pre
              units
              tahoe {
                label
                value
              }
              boca {
                label
                value
              }
              donner {
                label
                value
              }
              independence {
                label
                value
              }
              prosser {
                label
                value
              }
              stampede {
                label
                value
              }
            }
          }
          lakeTahoeLevel {
            heading
            label
            max
            min
            percentage
            units
            value
            back {
              content
            }
            override
          }
          waterTreatmentPlantProduction {
            heading
            value
            units
            pre
            post {
              left
              right
            }
            override
            back {
              post
              pre
            }
          }
          hydroelectricProduction {
            heading
            pre
            post {
              pre
              value
              post
            }
            units
            value
            back {
              content
            }
            override
          }
        }
      }
    }
  }
`;
